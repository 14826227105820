import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/plugins/vant'
// import './util/rem.js'

import 'amfe-flexible';
import "./assets/css/reset.css";
import "./assets/css/common.css";
import './assets/css/icon.css'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
