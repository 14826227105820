import Vue from 'vue'
// 在这里引入你所需的组件
import {
  Toast,
  // Slider,
    // CellGroup,
    // Icon
    // 。。。
} from 'vant'

// 按需引入UI组件
Vue.use(Toast)
// Vue.use(Slider)
import 'vant/lib/index.css';
// Vue.use(Cell)
// Vue.use(CellGroup)
// Vue.use(Icon)