import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'EpubBook',
    component: () => import(/* webpackChunkName: "EpubBook" */ '../views/epub-book.vue'),
    meta: {
      title: process.env.VUE_APP_NAME
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router
